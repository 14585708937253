.navigation-off-canvas {
	&.nav-sections {

		.navigation {

			.submenu .level1 .submenu{
				background: rgba(255, 255, 255, 0.97);
			}

			.submenu:not(:first-child) {

				&.expanded {
					margin-bottom: 0 !important;
				}
			}
		}
	}
}
