// - Navigation
$theme-navigation-background:                       $theme-primary-color                                !default;
$theme-navigation-color:                            $theme-primary-alternative-font-color               !default;
$theme-navigation-shadow:                           none                                                !default;
$theme-navigation-desktop-heading-hover-background: rgb(255, 255, 255)                                  !default;
$theme-navigation-desktop-heading-hover-color:      $theme-navigation-background                        !default;
$theme-navigation-desktop-submenu-background:       transparent                                         !default;
$theme-navigation-desktop-submenu-color:            $theme-navigation-background                        !default;
$theme-navigation-desktop-dropdown-background:      rgba(255, 255, 255, 0.97)                           !default;
$theme-navigation-desktop-item-arrow-color:         $theme-navigation-background                        !default;
$theme-navigation-mobile-heading-background:        $theme-navigation-background                        !default;

// + Product card
$theme-card-flexpay-background:                     $theme-accent-color                                 !default;
$theme-card-basket-style:                           1                                                   !default;
$theme-card-wishlist-style:                         2                                                   !default;

// + Header
// - Store settings
$theme-logo-max-width:                              230px                                               !default;


// + Product page
$theme-product-basket-button-style:                 1                                                   !default;
$theme-product-wishlist-button-style:               2                                                   !default;

// Table
$table-orders-head-font-color: $white;
