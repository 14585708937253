// ========== TYPOGRAPHY ==========
$theme-font-family:                     'Nunito', 'Gotham', -apple-system, "Helvetica Neue", sans-serif;

// ========== COLOR ==========
$white:                                 #ffffff;

$theme-primary-color:                   #0800B9;
$theme-secondary-color:                 #2A6FF3;
$theme-tertiary-color:                  #0800B9;
$theme-accent-color:                    #1D8293;

$theme-primary-font-color:              #333333                     !default;
$theme-primary-alternative-font-color:  $white                      !default;
$theme-secondary-alternative-font-color:$white                      !default;
$theme-tertiary-alternative-font-color: $white                      !default;
$theme-accent-alternative-font-color:   $white                      !default;

$theme-header-background:               $white;
$theme-body-background:                 #f6f6f6;
$theme-footer-background:               $white;
$theme-footer-color:                    $theme-primary-font-color;

$theme-price-font-color:                $theme-primary-font-color;

// ========== COMPONENTS ==========

// + Buttons
    // - Primary
$theme-button-style-1-background:       #2A6FF3;
$theme-button-style-1-color:            $white;
$theme-button-style-1-border-color:     $white;

    // - Wishlist
$theme-button-style-2-background:       $white;
$theme-button-style-2-color:            #2A6FF3;
$theme-button-style-2-border-color:     $white;

    // - Outlined
$theme-button-style-3-background:       $white;
$theme-button-style-3-color:            #2A6FF3;
$theme-button-style-3-border-color:     #2A6FF3;
