.opc {
	&-summary-wrapper {

		.minicart-items {

			.product {

				.cart-price {
					color: $theme-primary-font-color;
					@include font(14, 21, $global-weight-skinny);
				}

				.cash {
					color: $theme-primary-font-color;
					@include font(14, 21, $global-weight-skinny);
				}
			}
		}
		.mark,
		.amount {
			@include breakpoint(large) {
				@include font(16, 22, $global-weight-semibold);
			}

			@include breakpoint(medium down) {
				@include font(14, 14, $global-weight-semibold);
			}
		}
	}
}
