// .parent a.level-top span:nth-child(2)::after {
//   @include breakpoint(xlarge) {
//     display: none;
//   }
// }

.navigation {
  .megamenu.level-top-sectioned {
    .sectioned {
      background-color: rgba(255, 255, 255, 0.97);
    }
  }
  .level0 {
    .submenu {
      background: transparent;
    }
  }
}
