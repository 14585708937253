/* Slider */

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: #cccccc !default;
$slick-dot-color-active: $theme-carousel-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 24px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    .slick-slide {
        .product {
            &-item {
                max-width: 100%;
                padding-left: rem-calc(10px);
                padding-right: rem-calc(10px);

                &-info {
                    @include breakpoint(large only) {
                        margin-right: 0.5rem;
                    }
                    .price-container {
                        flex-direction: column;
                        .price {
                            @include breakpoint(medium down) {
                                white-space: pre-line;
                            }
                        }
                    }
                    .product-item-actions {
                        @include breakpoint(large down) {
                            button[data-action="add-to-wishlist"] {
                                border-right: none;
                            }
                            .change:after {
                                display: none;
                            }
                        }
                    }
                }
                &-info:hover {
                    .product-item-details {
                        opacity: 1;
                        pointer-events: all;

                        .product-item-inner {
                            .product-item-actions {
                                opacity: 1;
                                pointer-events: all;
                                @include breakpoint(medium down) {
                                    visibility: hidden;
                                }
                            }
                        }
                    }
                    .product-item-name {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    line-height: 1;

    @include breakpoint(medium) {
        margin-left: $global-margin;
    }

    &:first-of-type {
        margin-left: 0;
    }


    [dir="rtl"] & {
        float: right;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}


.slick-next {
    &:before {
        content: '\f102';
        padding-left: 5px;
    }
}
    .slick-dots {
        bottom: -25px;
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        width: 100%;

        @include breakpoint(large down) {
            bottom: 0;
        }

        li {
            position: relative;
            display: inline-block;
            height: 20px;
            width: 20px;
            margin: 0px;
            padding: 6px;
            cursor: pointer;
            button {
                border: 0;
                background: $slick-dot-color;
                display: block;
                height: 8px;
                width: 8px;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                padding: 0px;
                border-radius: 50%;
                cursor: pointer;
                &:hover, &:focus {
                    outline: none;
                    &:before {
                        opacity: $slick-opacity-on-hover;
                    }
                }
                &:before {
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    width: 24px;
                    height: 24px;
                    font-family: $slick-font-family;
                    font-size: $slick-dot-size;
                    line-height: 20px;
                    text-align: center;
                    color: $theme-secondary-color;
                    opacity: $slick-opacity-not-active;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
            &.slick-active button:before {
                color: $theme-secondary-color;
                opacity: 1;
            }
            &.slick-active button {
                background: $slick-dot-color-active;
            }
        }
    }


.slick-prev:active, .slick-next:active, .slick-prev:focus, .slick-next:focus, .slick-prev:not(.primary), .slick-next:not(.primary) {
    background: rgba(252, 252, 252, 0.7);
    border: none;
    box-shadow: none;
}

.slick-prev, .slick-next {
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    background: rgba(252, 252, 252, 0.6);
    border: none;
    border-radius: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 60px;
    line-height: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 60px;
    z-index: 101;
}
.slick-prev {
    left: 2rem;
}
.slick-next {
    right: 2rem;
}
button, a.action.primary {

    border-radius: 3px;

}

.slick-next::before {
    content: '\f102';
    padding-left: 5px;
}

.slick-prev::before, .slick-next::before, .slick-prev::after, .slick-next::after {

    color: #555555;
    font-size: 38px;
    opacity: 1;

}

.slick-prev::before {
    content: $slick-prev-character;
    padding-left: 5px;
}

.slick-next::before {

    content: $slick-next-character;
    padding-left: 5px;

}

.pagebuilder-icon, .slick-prev::before, .slick-next::before, .slick-prev::after, .slick-next::after {

    font-family: 'pagebuilder-font';
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;

}

.slick-prev, .slick-next {

    color: transparent;
    cursor: pointer;
    font-size: 0;
    line-height: 0;

}

.slick-slide {
    margin-left: 0;
}

.page-layout-2columns-left {
    .slick-slider {
        .slick-list {
            .product-item {
                @include breakpoint(large only) {
                    padding-left: 0;
                }
            }
            .product-item-info {
                .product-item-actions {
                    padding: 0;
                }
                .product-item-details {
                    .price-container {
                        .price {
                            white-space: pre-line;
                        }
                    }
                }
            }
        }
    }
}

.catalog-product-view {
    .slick-slider {
        .slick-list {
            .product-item-info {
                margin-right: 0.5rem;
            }
        }
    }
}

ol.product-items {
    &.widget-product-carousel {
        display: block;

        .slick-list {
            overflow: visible;
        }

        .slick-slide {
            .product-item {
                .product-item-actions {
                    @include breakpoint(large down) {
                        display: none;
                    }
                }
                .product-item-info:hover {
                    .product-item-name {
                        @include breakpoint(large down) {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .slick-dots {
            @include breakpoint(xlarge down) {
                bottom: -25px;
            }
            @include breakpoint(large down) {
                bottom: 0;
            }
        }
        .slick-track {
            margin-left: 0;
            .product-item-actions {
                .wishlist {
                    .items {
                        top: 6px;
                        padding: rem-calc(30px 0 0);
                        max-width: rem-calc(212px);
                        .item > span {
                            padding: rem-calc(5px 15px);
                        }
                    }
                    &.change {
                        @include breakpoint(large down) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

ol.product-items.widget-product-carousel:has(button.active) .slick-dots {
    z-index: -1;
}

.wishlist-index-index {
    .slick-slider {
        .product-item-info {
            overflow: hidden;
        }
        .product-item:hover {
            .product-item-inner {
                @include breakpoint(large down) {
                    display: none;
                }
            }
        }
        .product-item-actions {
            @include breakpoint(medium down) {
                display: none;
            }
        }
    }
}
