.checkout {

  &-shipping-address, &-billing-address{
    legend.label {
      color: $theme-primary-font-color;
    }
  }
}

#checkout {
  .shipping-address-item, .billing-address-details {
    color: $theme-primary-font-color;
  }
}

.checkout-success {
  p {
    color: $theme-primary-font-color;
  }
}

div[name="shippingAddress.postcode"] {
  div.message.warning {
    color: $theme-primary-font-color;
  }
}

.checkout-container,
.checkout-index-index .modal-popup {

  select {
		-webkit-appearance: auto;
		-moz-appearance: auto;
		appearance: auto;
		background-image: none;
		padding-right: rem-calc(40px);

		&:after {
			content: 'a';
		}
	}
}
