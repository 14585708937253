.delivery-country {
  @include flex;
  @include flex-align($y: middle);

    button:before {
      @include ird-icon($ird-icon-van);
      display: block;
      font-size: rem-calc(24px);
      margin-right: rem-calc(5px);
      @include breakpoint(xlarge) {
        color: $theme-primary-font-color;
      }
      @include breakpoint(large down) {
        color: $white;
      }
    }

  button {
    font-size: 0;
  }
}
